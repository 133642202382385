<template>
  <div class="fill-height">
    <v-container class="privacy-page pb-15 mb-15">
      <v-card max-width="500" class="mx-auto mt-12 pa-12 text-center">
        <v-card-title class="text-center"><h1 class="main-h2 mx-auto">404</h1></v-card-title>
        <v-card-text class="text-center">
          <div class="pb-7 pt-4">
            {{ $t("Description") }}
          </div>
          <v-text-field
            :placeholder="$t('Search')"
            outlined
            rounded
            v-model="query"
            dense
            append-icon="$vuetify.icons.fasSearch"
            @click:append="search"
            v-on:keyup.enter="search"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Title": "Page not found",
    "Search": "Search...",
    "Description": "The page you requested could not found"
    },
    "sv": {
    "Title": "Sidan kunde inte hittas",
    "Search": "Sök...",
    "Description": "Sidan du letade efter kunde inte hittas"
    }
    }
</i18n>

<script>
export default {
  name: "error_404",
  metaInfo() {
    return {
      title: this.$t("Title"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Title") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
      ],
    };
  },
  data: () => ({
    query: "",
  }),
  methods: {
    search() {
      if (this.query != "") this.$router.push("/search?q=" + this.query);
    },
  },
};
</script>